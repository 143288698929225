import { Grid, Typography } from '@mui/material'

import { ExampleGrid } from './styles'

export default function ExampleComponent(): JSX.Element {
  return (
    <ExampleGrid container>
      <Grid item xs={12} md={5} lg={5} minWidth="350px">
        <Typography variant="h1">Your Content here</Typography>
      </Grid>
      <Grid item xs={0} md={7} lg={7}>
        More Content here
      </Grid>
    </ExampleGrid>
  )
}
