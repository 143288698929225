import { createTheme } from '@mui/material'
import { red } from '@mui/material/colors'

import { colors } from './color'

export * from './color'

const theme = createTheme({
  palette: {
    primary: {
      main: colors.primary.light,
      light: colors.primary.light,
      dark: colors.primary.dark,
    },
    secondary: {
      main: colors.secondary.light,
      light: colors.primary.light,
      dark: colors.secondary.dark,
    },
    error: {
      main: red.A400,
    },
    background: {
      default: '#fff',
    },
  },
  typography: {
    fontFamily: [
      'Montserrat',
      'Open Sans',
      'Roboto',
      'Arial',
      'sans-serif',
    ].join(','),
    allVariants: {},
    h1: {
      fontFamily: 'Montserrat',
      fontWeight: 'bold',
      fontSize: '2rem',
      marginBottom: '1rem',
      lineHeight: 1,
      color: colors.font.default.header,
    },
    h2: {
      fontFamily: 'Montserrat',
      fontWeight: 'bold',
      fontSize: '1.5rem',
      marginBottom: '1rem',
      lineHeight: 1,
      color: colors.font.default.header,
    },
    h3: {
      fontFamily: 'Montserrat',
      fontWeight: 600,
      fontSize: '1.25rem',
      marginBottom: '1rem',
      lineHeight: 1,
      color: colors.font.default.header,
    },
    h4: {
      fontFamily: 'Montserrat',
      fontWeight: 600,
      fontSize: '1.125rem',
      marginBottom: '1rem',
      lineHeight: 1,
      color: colors.font.default.header,
    },
    h5: {
      fontFamily: 'Montserrat',
      fontWeight: 600,
      fontSize: '1rem',
      marginBottom: '1rem',
      lineHeight: 1,
      color: colors.font.default.header,
    },
    h6: {
      fontFamily: 'Montserrat',
      fontSize: '1rem',
      marginBottom: '1rem',
      lineHeight: 1,
      color: colors.font.default.header,
    },
    subtitle1: {
      fontFamily: 'Open Sans',
      fontWeight: 600,
      fontSize: '1rem',
      marginBottom: '1rem',
    },
    subtitle2: {
      marginBottom: '1rem',
    },
    body1: {
      fontFamily: 'Open Sans',
      fontWeight: 'normal',
      fontSize: '1rem',
      color: colors.font.default.body,
    },
    body2: {
      fontFamily: 'Open Sans',
      fontWeight: 'normal',
      fontSize: '0.875rem',
      color: colors.font.default.body,
    },
    button: {
      fontFamily: 'Montserrat',
      fontWeight: 600,
      fontSize: '1rem',
    },
    caption: {
      fontFamily: 'Open Sans',
      fontSize: '0.75rem',
      lineHeight: 1,
      margin: 0,
      color: colors.font.default.body,
    },
    overline: {},
  },
  components: {
    MuiAutocomplete: {
      defaultProps: {
        autoComplete: true,
        autoHighlight: true,
        autoSelect: true,
        blurOnSelect: true,
        clearOnEscape: false,
        disableClearable: true,
        disableListWrap: true,
        openOnFocus: true,
      },
      styleOverrides: {
        root: {
          height: 48,
        },
        inputRoot: {
          height: 48,
        },
      },
    },
    MuiButton: {
      defaultProps: {
        variant: 'contained',
        disableElevation: true,
      },
      styleOverrides: {
        root: {
          textTransform: 'none',
          fontVariantCaps: 'normal',
          minWidth: 112,
        },
        sizeLarge: {
          minHeight: 48,
        },
        contained: {
          border: 0,
          borderRadius: 4,
          backgroundColor: colors.primary.light,
          '&:hover': {
            backgroundColor: colors.primary.A400,
          },
          '&$disabled': {
            backgroundColor: colors.secondary.dark,
          },
          '&:focus': {
            border: colors.secondary,
            color: 'white',
          },
        },
        containedSizeMedium: {
          color: 'white',
        },
        outlined: {},
        text: {},
      },
    },
    MuiChip: {
      styleOverrides: {
        label: {
          fontSize: '0.75rem',
          fontWeight: 600,
        },
      },
    },
    MuiCheckbox: {
      defaultProps: {
        color: 'primary',
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          '&:hover $notchedOutline': {
            borderColor: colors.secondary.A300,
          },
          '&$focused $notchedOutline': {
            borderColor: colors.primary.light,
          },
        },
        input: {
          padding: '12.5px 14px',
        },
      },
    },
    MuiInput: {
      styleOverrides: {
        root: {
          height: 48,
        },
        input: {
          padding: '12.5px 14px',
        },
      },
    },
    MuiTextField: {
      defaultProps: {
        helperText: true,
      },
      styleOverrides: {
        root: {},
      },
    },
  },
})

export default theme
