import { useEffect } from 'react'

import { Grid } from '@mui/material'

import ExampleComponent from '../../components/ExampleComponent'
import { ExampleGrid } from './styles'

export default function ExamplePage(): JSX.Element {
  useEffect(() => {
    document.title = 'Example Page'
  }, [])

  return (
    <ExampleGrid container>
      <Grid item xs={12} md={5} lg={5} minWidth="350px">
        Your Component Here:
        <ExampleComponent />
      </Grid>
      <Grid item xs={0} md={7} lg={7}>
        Your other component
      </Grid>
    </ExampleGrid>
  )
}
